import { render, staticRenderFns } from "./customer-select.vue?vue&type=template&id=3a9d6660&scoped=true&"
import script from "./customer-select.vue?vue&type=script&lang=ts&"
export * from "./customer-select.vue?vue&type=script&lang=ts&"
import style0 from "./customer-select.vue?vue&type=style&index=0&id=3a9d6660&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9d6660",
  null
  
)

export default component.exports



















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Select, Option, OptionGroup } from 'element-ui';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import BranchesModel from '../models/branches.model';
import CarsSharedService, { CarsSharedServiceS } from '../cars-shared.service';

@Component({
    components: {
        Select,
        Option,
        OptionGroup,
        StyledSearchbar,
    },
})
export default class CustomerSelect extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    selectedChain = null;

    get chains(): BranchesModel[] {
        const { branches } = this.carsSharedService;
        return branches;
    }

    setChain(value: BranchesModel) {
        this.carsSharedService.currentChain = value;
        document.location.reload();
    }
}
